import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CrossIcon } from "../SvgIcons/allIcons";
import { constant } from "../utils/constants";

const FileModal = ({ show, handleClose, handleAction }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header className="deleteUser_ModalHeader" closeButton={false}>
        <Modal.Title>
          {/* {`Do you want to ${show.type} file for ${show?.company?.label}?`} */}
          {`Do you want to ${show.type} file ?`}
        </Modal.Title>
        <Button variant="link" onClick={handleClose} className="close_Btn">
          <CrossIcon />
        </Button>
      </Modal.Header>
      {show?.type == constant.FILE_ACTION.IMPORT && (
        <Modal.Body>
          <p className="ms-4">
            File Name : <span>{show?.file?.name}</span>
          </p>
        </Modal.Body>
      )}

      <Modal.Footer className="deleteUser_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme"
          type="button"
          onClick={() => handleAction(show)}
        >
          {show?.type}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileModal;
