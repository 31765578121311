import axios from "axios";
import { store } from "../redux/store";
import { constant } from "../utils/constants";
import i18next from "i18next";

/* INTERCEPTOR STARTS */
const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  "Access-Control-Allow-Origin": "*",
});

http.interceptors.request.use(
  function (config) {
    const token = store.getState()?.auth?.details?.token?.auth;
    if (token) {
      config.headers["authorization"] = `${token}`;
    }
    if (i18next?.language)
      config.headers["accept-language"] = i18next?.language;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* INTERCEPTOR ENDS */

/********************* AUTHENTICATION STARTS *********************/
export const signIn = async (body) => {
  return await http.post(`/auth/users/login`, body);
};

export const logOut = async () => {
  return await http.get(`/auth/users/logout`);
};

export const forgetPass = async (body) => {
  return await http.post(`/auth/users/forgot-password`, body);
};

export const verifyOtp = async (body) => {
  return await http.post(`/auth/users/reset-password-otp-verify`, body);
};

export const resendOtp = async (body) => {
  return await http.post(`/auth/users/resend`, body);
};

export const resetPassword = async (body) => {
  return await http.post(`/auth/users/reset-password`, body);
};

export const editProfile = async (body) => {
  return await http.put(`/auth/users/update-user`, body);
};

export const profileImageUplaod = async (body) => {
  return await http.post(`/auth/users/upload/profile-pic`, body);
};

export const addCompany = async (body) => {
  return await http.post(`/auth/company`, body);
};

export const updateCompany = async (id, body) => {
  return await http.put(`/auth/company/${id}`, body);
};

export const getOneCompnay = async (id, location = false, brand = false) => {
  return await http.get(
    `/auth/company/${id}?companyLocations=${location}&companyBrands=${brand}`
  );
};

export const getAllBrand = async (page, search, limit = 10) => {
  const params = { page: page, limit: limit };
  if (search) {
    params.search = search;
  }

  return await http.get(`/auth/brands`, {
    params: params,
  });
};

export const addBrand = async (body) => {
  return await http.post(`/auth/brands`, body);
};

export const updateBrand = async (id, body) => {
  return await http.put(`/auth/brands/${id}`, body);
};

export const deleteBrand = async (id) => {
  return await http.delete(`/auth/brands/${id}`);
};

export const addBrandLocation = async (header, body) => {
  return await http.post(`/auth/company/location`, body, {
    headers: {
      companydomain: header,
    },
  });
};

export const getAllCompany = async (
  page,
  limit,
  search,
  sortBy = constant.ORDER_BY.ASC,
  sortedBy = "name",
  haveLocations = "false"
) => {
  const params = {
    page: page,
    limit: limit,
    sortBy: sortBy,
    sortedBy: sortedBy,
    haveLocations: haveLocations,
  };
  if (search) params.search = search;
  return await http.get(`/auth/company`, {
    params: params,
  });
};

export const deleteCompany = async (id) => {
  return await http.delete(`/auth/company/${id}`);
};

export const getCompanyBrands = async (header) => {
  return await http.get(`/auth/company/brand`, {
    headers: {
      companydomain: header,
    },
  });
};

export const getCompanyBrandsDropDown = async (header, page, limit, search) => {
  let params = { page: page, limit: limit };
  if (search) params.search = search;
  return await http.get(`/auth/company/brand`, {
    headers: {
      companydomain: header,
    },
    params: params,
  });
};

export const getBrandDetails = async (id, header) => {
  return await http.get(`/auth/company/brand/${id}`, {
    headers: { companydomain: header },
  });
};

export const updateBrandLocation = async (id, header, body) => {
  return await http.put(`/auth/company/location/${id}`, body, {
    headers: {
      companydomain: header,
    },
  });
};
export const getCompanyLocation = async (header, page, limit, search) => {
  let params = { page: page, limit: limit };
  if (search) params.search = search;
  return await http.get(`/auth/company/location`, {
    headers: { companydomain: header },
    params: params,
  });
};
export const getLocationDetails = async (id, header) => {
  return await http.get(`/auth/company/location/${id}`, {
    headers: { companydomain: header },
  });
};

export const deleteCompanyLocation = async (id, header) => {
  return await http.delete(`/auth/company/location/${id}`, {
    headers: { companydomain: header },
  });
};

export const deleteCompanyBrand = async (id, header) => {
  return await http.delete(`/auth/company/brand/${id}`, {
    headers: { companydomain: header },
  });
};

export const associateBrand = async (body, header) => {
  return await http.post(`/auth/company/brand`, body, {
    headers: { companydomain: header },
  });
};

export const updateAssociatedBrand = async (id, body, header) => {
  return await http.put(`/auth/company/brand/${id}`, body, {
    headers: { companydomain: header },
  });
};

export const addCustomer = async (body) => {
  return await http.post(`/auth/users`, body);
};

export const getUser = async (
  page,
  limit,
  search,
  role,
  companyId,
  brandId
) => {
  let params = { page: page, limit: limit, role: role };
  if (search) params.search = search;
  if (companyId) params.companyId = companyId;
  if (brandId) params.brandId = brandId;

  return await http.get(`/auth/users`, { params: params });
};

export const getUserDetails = async (userId, userDetails = false) => {
  let params = {};
  if (userId) params.userId = userId;
  if (userDetails) params.userDetails = userDetails;
  return await http.get(`/auth/users/user-profile`, {
    params: params,
  });
};

export const deleteUser = async (id) => {
  return await http.delete(`/auth/users/${id}`);
};

export const editUser = async (id, body) => {
  return await http.put(`/auth/users/${id}`, body);
};

export const addPlan = async (body) => {
  return await http.post(`/auth/plans`, body);
};

export const getPlans = async () => {
  return await http.get(`/auth/plans`);
};

export const updatePlan = async (id, body) => {
  return await http.put(`/auth/plans/${id}`, body);
};

export const deletePlan = async (id) => {
  return await http.delete(`/auth/plans/${id}`);
};

/**************************Product Services******************/

export const addCategory = async (header, body) => {
  return await http.post(`/product/category`, body, {
    headers: { companydomain: header },
  });
};

export const uploadCategoryIcon = async (body) => {
  return await http.post(`/product/category/file-upload/single`, body);
};

export const getCategory = async (
  header,
  page,
  limit,
  search,
  location = true
) => {
  let params = { page: page, limit: limit, locationDetails: location };
  if (search) params.search = search;
  return await http.get(`/product/category`, {
    headers: { companydomain: header },
    params: params,
  });
};

export const deleteCatergory = async (header, id) => {
  return await http.delete(`/product/category/${id}`, {
    headers: { companydomain: header },
  });
};

export const getCategoryDetails = async (header, id) => {
  return await http.get(`/product/category/${id}`, {
    headers: { companydomain: header },
  });
};

export const updateCategory = async (header, id, body) => {
  return await http.put(`/product/category/${id}`, body, {
    headers: { companydomain: header },
  });
};

export const addDataList = async (body, header) => {
  return await http.post(`/product/datalist`, body, {
    headers: { companydomain: header },
  });
};

export const getDataList = async (
  page,
  limit,
  search,
  type,
  header,
  locationDetails = false,
  customerDetails = false
) => {
  let params = {
    page: page,
    limit: limit,
    type: type,
    locationDetails: locationDetails,
    customerDetails: customerDetails,
  };
  if (search) params.search = search;
  return await http.get(`/product/datalist`, {
    params: params,
    headers: { companydomain: header },
  });
};

export const deleteDataList = async (id, header) => {
  return await http.delete(`/product/datalist/${id}`, {
    headers: { companydomain: header },
  });
};

export const getDataListbyId = async (
  id,
  header,
  locationDetails = false,
  customerDetails = false
) => {
  let params = {
    locationDetails: locationDetails,
    customerDetails: customerDetails,
  };
  return await http.get(`/product/datalist/${id}`, {
    headers: { companydomain: header },
    params,
  });
};

export const updateDataList = async (id, body, header) => {
  return await http.put(`/product/datalist/${id}`, body, {
    headers: { companydomain: header },
  });
};

export const addTaskImage = async (body) => {
  return await http.post(`/product/datalist/file-upload/single`, body);
};

export const addProduct = async (body, header) => {
  return await http.post(`/product/products`, body, {
    headers: { companydomain: header },
  });
};

export const getProductList = async (
  page,
  limit,
  header,
  locationDetails = false,
  customerDetails = false
) => {
  let params = {
    page: page,
    limit: limit,
    locationDetails: locationDetails,
    customerDetails: customerDetails,
  };
  return await http.get(`/product/products`, {
    params: params,
    headers: { companydomain: header },
  });
};

export const deleteProduct = async (id, header) => {
  return await http.delete(`/product/products/${id}`, {
    headers: { companydomain: header },
  });
};

export const getProductDetails = async (
  id,
  header,
  locationDetails = false
) => {
  return await http.get(
    `/product/products/${id}?locationDetails=${locationDetails}`,
    {
      headers: { companydomain: header },
    }
  );
};

export const updateProduct = async (id, body, header) => {
  return await http.put(`/product/products/${id}`, body, {
    headers: { companydomain: header },
  });
};

export const addItem = async (body, header) => {
  return await http.post(`/product/items`, body, {
    headers: { companydomain: header },
  });
};

export const addItemImages = async (type, body) => {
  return await http.post(`/product/items/file-upload/single`, body, {
    params: { type: type },
  });
};

export const getItems = async (page, limit, header, location = true) => {
  let params = {
    page: page,
    limit: limit,
    locationDetails: location,
  };
  return await http.get(`/product/items`, {
    params: params,
    headers: { companydomain: header },
  });
};

export const getItemDetails = async (id, header) => {
  return await http.get(`/product/items/${id}`, {
    params: { locationDetails: true },
    headers: { companydomain: header },
  });
};

export const downloadCategoryExcel = async (header) => {
  return await http.get(`/product/category/excel/sample?domain=${header}`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const exportCategoryExcel = async (header) => {
  return await http.get(`/product/category/excel/export-data`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const handleImportCatergory = async (body, header) => {
  return await http.post(`/product/category/excel/import`, body, {
    headers: { companydomain: header },
  });
};

export const getAllPrinters = async (
  header,
  page,
  limit,
  search,
  location = true
) => {
  let params = { page: page, limit: limit, locationDetails: location };
  if (search) params.search = search;
  return await http.get(`/auth/company/printer`, {
    headers: { companydomain: header },
    params: params,
  });
};

export const deletePrinters = async (header, id) => {
  return await http.delete(`/auth/company/printer/${id}`, {
    headers: { companydomain: header },
  });
};

export const getPrinterDetails = async (header, id) => {
  return await http.get(`/auth/company/printer/${id}`, {
    headers: { companydomain: header },
  });
};

export const updatePrinter = async (header, id, body) => {
  return await http.put(`/auth/company/printer/${id}`, body, {
    headers: { companydomain: header },
  });
};

export const addPrinter = async (body, header) => {
  return await http.post(`/auth/company/printer`, body, {
    headers: { companydomain: header },
  });
};

export const getLabelSizes = async (page, limit, search) => {
  let params = { page: page, limit: limit };
  if (search) params.search = search;
  return await http.get(`/product/masterdata/labelSize`, {
    params: params,
  });
};

export const addLabel = async (body) => {
  return await http.post(`/product/labels`, body);
};

export const addLabelImage = async (body) => {
  return await http.post(`/product/labels/file-upload/single`, body);
};

export const getLabels = async (params) => {
  return await http.get(`/product/labels`, { params });
};

export const deleteLabel = async (id) => {
  return await http.delete(`/product/labels/${id}`);
};

export const getLabelDetails = async (id) => {
  return await http.get(
    `/product/labels/${id}?locationDetails=${true}&companyDetails=${true}`
  );
};

export const updateLabel = async (id, body) => {
  return await http.put(`/product/labels/${id}`, body);
};

export const downloadItemExcel = async (header) => {
  return await http.get(`/product/items/excel/sample`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const exportItemExcel = async (header) => {
  return await http.get(`/product/items/excel/export-data`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};
export const exportUserExcel = async ({domain,params}) => {
  return await http.get(`/auth/users/excel/export-data`, {
    headers: { companydomain: domain },
    responseType: "blob",
    params:params
  });
};


export const downloadUserExcel = async (header) => {
  return await http.get(`/auth/users/excel/sample`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const handleUserImport = async (body, header) => {
  return await http.post(`/auth/users/excel/import`, body, {
    headers: { companydomain: header },
  });
};
export const handleImportItem = async (body, header) => {
  return await http.post(`/product/items/excel/import`, body, {
    headers: { companydomain: header },
  });
};

export const downloadProductExcel = async (header) => {
  return await http.get(`/product/products/excel/sample`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const exportProductExcel = async (header) => {
  return await http.get(`/product/products/excel/export-data`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const handleImportProduct = async (body, header) => {
  return await http.post(`/product/products/excel/import`, body, {
    headers: { companydomain: header },
  });
};

export const downloadDataListExcel = async (header, type) => {
  return await http.get(`/product/datalist/excel/sample?type=${type}`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const exportDataListExcel = async (header, type) => {
  return await http.get(`/product/datalist/excel/export-data?type=${type}`, {
    headers: { companydomain: header },
    responseType: "blob",
  });
};

export const handleImportDataList = async (body, header, type) => {
  return await http.post(`/product/datalist/excel/import?type=${type}`, body, {
    headers: { companydomain: header },
  });
};

export const getAllTransactions = async (params) => {
  return await http.get(`/auth/transactions`, {
    params: params,
  });
};

export const addNotification = async (body) => {
  return await http.post(`/auth/notifications`, body);
};

export const getReports = async (params) => {
  return await http.get(`/auth/users/reports-analytics`, { params });
};

export const getItemReports = async (header, params) => {
  return await http.get(`/product/items/reports-analytics`, {
    params: params,
    headers: { companyDomain: header },
  });
};
