import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import i18next from "i18next";
import moment from "moment";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import NavigateBack from "../../components/NavigateBack";
import { unitsArr } from "../../helper/helper";
import useSlider from "../../hooks/useSlider";
import {
  addDataList,
  getAllCompany,
  getDataListbyId,
  getItems,
  updateDataList,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";

const AddReceivingList = () => {
  const dateRef = useRef();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  
  const {
    values,
    touched,
    setValues,
    handleBlur,
    handleChange,
    errors,
    setFieldTouched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      company: "",
      name: "",
      date: "",
      vendor: "",
      item: "",
      unit: "",
      packCount: "",
      qtyReceived: "",
      location: [],
      printLabel: "false",
      assignAll: false,
    },
    validationSchema: yup.object().shape({
      company: yup.object().shape({
        value: yup.string().required().label(t("product.company")),
      }),
      name: yup.string().required().label(t("receiving.receivingListName")),
      date: yup.string().required().label(t("receiving.deliveryReceivingDate")),
      vendor: yup.string().required().label(t("receiving.vendorDistributor")),
      item: yup.object().shape({
        value: yup.string().required().label(t("receiving.item")),
      }),
      unit: yup.object().shape({
        value: yup.string().required().label(t("receiving.unitMeasure")),
      }),
      packCount: yup
        .number()
        .required()
        .typeError(t("errorMsg.invalidNumber"))
        .positive()
        .min(1)
        .label(t("receiving.packCount")),
      qtyReceived: yup
        .number()
        .required()
        .typeError(t("errorMsg.invalidNumber"))
        .positive()
        .min(1)
        .label(t("receiving.qtyToReceive")),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.name,
        type: constant.DATA_LIST.RECEIVING_LIST,
        itemId: values?.item?.value,
        assignAll: values?.assignAll,
        details: {
          vendorName: values?.vendor,
          deliveryDate: moment(values?.date)?.format(),
          measureUnit: values?.unit?.value,
          packCount: values?.packCount,
          receivedQuantity: values?.qtyReceived,
          printLabel: values?.printLabel,
        },
      };
      if (!values?.assignAll || values?.assignAll == "false") {
        body.locations = values?.location;
      }
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      !!id
        ? updateDataList(id, body, values?.company?.domain)
        : addDataList(body, values?.company?.domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("../receivinglist", {
        state: location?.state ? location?.state : values?.company,
      });
    },
  });

  useQuery({
    queryKey: ["receiving-details", id],
    queryFn: async () => {
      const resp =
        !!location?.state?.domain &&
        !!id &&
        (await getDataListbyId(id, location?.state?.domain, false, true));
      let data = resp?.data?.data;
      if (data) {
        setValues({
          ...values,
          company: location?.state,
          name: data?.name,
          date: moment(data?.details?.deliveryDate).format("YYYY-MM-DD"),
          vendor: data?.details?.vendorName,
          item: {
            value: data?.itemDetails?._id,
            label: data?.itemDetails?.en_name,
            location: data?.itemDetails?.locations,
            assignAll: data?.itemDetails?.assignAll,
          },
          unit: unitsArr.find((i) => i.value == data?.details?.measureUnit),
          packCount: data?.details?.packCount,
          qtyReceived: data?.details?.receivedQuantity,
          location: data?.itemDetails?.locations,
          printLabel: String(data?.details?.printLabel),
          assignAll: data?.assignAll,
        });
      }
      return true;
    },
  });

  const loadItems = async (search, loadedOptions, { page }) => {
    try {
      let resp =
        values?.company?.domain &&
        (await getItems(
          page,
          constant.PER_PAGE_TEN,
          values?.company?.domain,
          false
        ));

      let array = (await resp?.data?.data?.items) ?? [];
      return {
        options: array.map((item) => ({
          value: item?._id,
          label: item?.en_name,
          locations: item?.locations,
          assignAll: item?.assignAll,
        })),
        hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log("err", err);
    }
  };

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadUnits = async () => {
    return {
      options: unitsArr,
      hasMore: false,
    };
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12}>
            <NavigateBack path="../receivinglist" state={location?.state}>
              {!!id
                ? t("receiving.editReceivingList")
                : t("receiving.addNewReceivingList")}
            </NavigateBack>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={12}>
              <div className="w-100">
                <label htmlFor="name" className="fontSize16 mb-2">
                  {t("user.company")} <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("user.selectCompany")}
                  id="company"
                  isDisabled={!!id}
                  loadOptions={loadCompany}
                  isClearable={false}
                  onChange={(e) => {
                    setFieldValue("company", e);
                    setFieldValue("locations", []);
                  }}
                  value={values?.company}
                  onBlur={() => setFieldTouched("company", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.company && errors?.company?.value}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="name" className="companyLabel">
                  {t("receiving.receivingListName")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  value={values?.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("receiving.enterReceivingListName")}
                />
                <small className="text-danger ms-1">
                  {touched?.name && errors?.name}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="date" className="companyLabel">
                  {t("receiving.deliveryReceivingDate")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  className="commonInput"
                  name="date"
                  value={values?.date}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={dateRef}
                  onClick={() => dateRef.current.showPicker()}
                />
                <small className="text-danger ms-1">
                  {touched?.date && errors?.date}
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="vendor" className="companyLabel">
                  {t("receiving.vendorDistributor")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="vendor"
                  value={values?.vendor}
                  name="vendor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("receiving.enterVendorDistributorName")}
                />

                <small className="text-danger ms-1">
                  {touched?.vendor && errors?.vendor}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="item" className="companyLabel">
                  {t("receiving.item")} <span className="text-danger">*</span>
                </label>
                <DropDown
                  id="item"
                  loadOptions={loadItems}
                  isClearable={false}
                  value={values?.item}
                  depends={values?.company?.domain}
                  onChange={(e) => {
                    setFieldValue("item", e);
                    setFieldValue("location", e?.locations);
                    setFieldValue("assignAll", e?.assignAll);
                  }}
                  onBlur={() => setFieldTouched("item", true)}
                  placeholder={t("receiving.enterItem")}
                />
                <small className="text-danger ms-1">
                  {touched?.item && errors?.item?.value}
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="unit" className="companyLabel">
                  {t("receiving.unitMeasure")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <DropDown
                  id="unit"
                  name="unit"
                  value={values?.unit}
                  defaultOptions={true}
                  loadOptions={loadUnits}
                  isClearable={false}
                  onChange={(e) => setFieldValue("unit", e)}
                  onBlur={() => setFieldTouched("unit", true)}
                  placeholder={t("receiving.selectUnitMeasure")}
                />

                <small className="text-danger ms-1">
                  {touched?.unit && errors?.unit?.value}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="packCount" className="companyLabel">
                  {t("receiving.packCount")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="packCount"
                  value={values?.packCount}
                  name="packCount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("receiving.enterPackCount")}
                />
                <small className="text-danger ms-1">
                  {touched?.packCount && errors?.packCount}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="qtyReceived" className="companyLabel">
                  {t("receiving.qtyToReceive")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="qtyReceived"
                  className="commonInput"
                  name="qtyReceived"
                  value={values?.qtyReceived}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("receiving.enterQuantityToReceive")}
                />
                <small className="text-danger ms-1">
                  {touched?.qtyReceived && errors?.qtyReceived}
                </small>
              </div>
            </Col>
            {/* <Col md={6}>
              <div className="mt-3">
                <label htmlFor="location" className="companyLabel">
                  {t("receiving.receivedLocaiton")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <DropDown
                  placeholder={t("receiving.enterReceivedLocation")}
                  id="location"
                  loadOptions={loadLocations}
                  isClearable={false}
                  isMulti={true}
                  value={values?.location}
                  onChange={(e) => setFieldValue("location", e)}
                  onBlur={() => setFieldTouched("location", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.location && errors?.location}
                </small>
              </div>
            </Col> */}
            <Col md={6} className="mt-3">
              <h3 className="fontSize16">{t("receiving.printLabel")}</h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="label-yes"
                    name="printLabel"
                    onChange={handleChange}
                    checked={values?.printLabel == "true"}
                    value={"true"}
                  />
                  <label htmlFor="label-yes" className="position_Label free">
                    {t("receiving.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="label-no"
                    name="printLabel"
                    onChange={handleChange}
                    checked={values?.printLabel == "false"}
                    value={"false"}
                  />
                  <label htmlFor="label-no" className="position_Label">
                    {t("receiving.no")}
                  </label>
                </div>
              </div>
            </Col>
            {/* <Col md={6} className="mt-3">
              <h3 className="fontSize16">
                {t("receiving.applyToAllfutureLocations")}
              </h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="location-yes"
                    name="assignAll"
                    onChange={handleChange}
                    checked={values?.assignAll == "true"}
                    value={"true"}
                  />
                  <label htmlFor="location-yes" className="position_Label free">
                    {t("receiving.yes")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="location-no"
                    name="assignAll"
                    onChange={handleChange}
                    checked={values?.assignAll == "false"}
                    value={"false"}
                  />
                  <label htmlFor="location-no" className="position_Label">
                    {t("receiving.no")}
                  </label>
                </div>
              </div>
            </Col> */}
            <Row>
              <Col className="mt-4" xl={12}>
                <button
                  className="userBtn btn-theme m-0"
                  type="button"
                  onClick={handleSubmit}
                >
                  {!!id ? t("globals.saveChanges") : t("globals.add")}
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddReceivingList;
